<template>
	<div id="app">
		<div class="tool-header" v-if="$route.path !== '/'">
			<router-link to="/" style="color: #007bff;text-decoration: none;"><h2>Follow Tool</h2></router-link>
		</div>
		<router-view></router-view>
		<div class="footer">
			<p> &copy; 2025 Follow Tool 版权所有 <a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备2025393221号-1</a><br/>追随你心中的理想，并永不放弃</p>
		</div>
		<return-button />
	</div>
</template>

<script>
	export default {
		name: 'App'
	}
</script>
