<template>
	<div class="return-btn">
		<el-button icon="el-icon-arrow-up" round fixed @click="scrollToTop()" v-if="$route.path === '/'" />
		<el-button icon="el-icon-arrow-left" round fixed @click="goBack()" v-if="$route.path !== '/'" />
		<!-- <router-link  v-if="$route.path === '/'">
			<el-button icon="el-icon-hot-water" round fixed style="margin-top: 5px;" />
		</router-link> -->
	</div>
</template>

<style lang="scss">
	.return-btn {
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 1000;
	}

	.el-button {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	}

	@media (min-width: 768px) {
		.return-btn {
			position: fixed;
			bottom: 50px;
			right: 50px;
			z-index: 1000;
		}
	}
</style>

<script>
	export default {
	name: 'ReturnButton',
	methods: {
		goBack() {
			this.$router.go(-1);
		}, 
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'  // 使用平滑滚动
			});
		}
	},
};
</script>
